import React, { ReactElement, ReactNode } from "react";
import { TabItem } from "./TabItems";

export interface TabContentProps {
  tab: TabItem;
  mainImage?: { src: string; height: number };
  children: ReactElement<TabSectionProps> | (ReactElement<TabSectionProps> | undefined)[];
}

export function TabContent(props: TabContentProps) {
  return (
    <div className={`py-5 text-center font-serif `}>
      {props.mainImage && (
        <img
          src={props.mainImage.src}
          alt={props.tab + "mainImage"}
          className={`aspect-auto mx-auto md:h-[var(--height)]`}
          style={{ "--height": `${props.mainImage.height}px` } as React.CSSProperties}
        ></img>
      )}
      {props.children}
    </div>
  );
}

export interface TabSectionProps {
  heading: string;
  children: ReactNode;
}

export function TabSection(props: TabSectionProps) {
  return (
    <div className="font-serif p-5">
      <h2 className="text-2xl text-center font-semibold">{props.heading}</h2>
      {props.children}
    </div>
  );
}
