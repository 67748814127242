import React from "react";
import { DATA } from "../../data/data";

export function Header() {
  return (
    <header className="px-auto pt-20 pb-10 text-center">
      <h1 className="font-head text-7xl font-normal p-2">{DATA.names}</h1>
      <h2 className="font-serif text-xl font-light uppercase p-2">We're gettting married!</h2>
      <h2 className="font-serif text-xl font-thin uppercase p-2">{formatDateString(DATA.date)}</h2>
    </header>
  );
}

function formatDateString(date: Date): string {
  const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" };

  const formattedDate: string = date.toLocaleDateString("en-US", options);

  // Add suffix to the day
  const day: number = date.getDate();
  let daySuffix: string = "th";

  if (day === 1 || day === 21 || day === 31) {
    daySuffix = "st";
  } else if (day === 2 || day === 22) {
    daySuffix = "nd";
  } else if (day === 3 || day === 23) {
    daySuffix = "rd";
  }

  const dayWithSuffix: string = day + daySuffix;
  const finalFormattedDate: string = formattedDate.replace(/\d{1,2}/, dayWithSuffix);

  return finalFormattedDate;
}
