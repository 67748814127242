import React, { useState } from "react";
import { Home } from "./Home";
import { Travel } from "./Travel";
import { Faq } from "./Faq";
import { Rsvp } from "./Rsvp";
import { Gifts } from "./Gifts";
import { DATA } from "../../data/data";
import { Photos } from "./Photos";
import { TabItem } from "./TabItems";

export interface TabInfo {
  label: string;
  path: string;
  element: JSX.Element;
  enabled: boolean;
}

export const Tabs: Record<TabItem, TabInfo> = {
  [TabItem.Home]: { label: "Home", path: "", element: <Home />, enabled: true },
  [TabItem.Travel]: { label: "Travel", path: "travel", element: <Travel />, enabled: true },
  [TabItem.Faq]: { label: "FAQ", path: "faq", element: <Faq />, enabled: true },
  [TabItem.Rsvp]: {
    label: "RSVP",
    path: "rsvp",
    element: <Rsvp />,
    enabled: true,
  },
  [TabItem.Gifts]: {
    label: "Gifts",
    path: "gifts",
    element: <Gifts />,
    enabled: true,
  },
  [TabItem.Photos]: {
    label: "Photos",
    path: "photos",
    element: <Photos />,
    enabled: DATA.photos ?? false,
  },
};

export const TabItems = Object.values(TabItem)
  .filter((x) => !isNaN(Number(x)))
  .map((x) => x as TabItem)
  .filter((x) => Tabs[x].enabled);

export interface TabsProps {
  currentTab: TabItem;
  selectTab: (tab: TabItem) => void;
}

export function DesktopTabBar(props: TabsProps) {
  return (
    <div className="flex-row items-center justify-center mx-auto text-center pb-5 font-serif hidden md:flex">
      {TabItems.map((x) => tabItem(x, x === props.currentTab, props.selectTab))}
    </div>
  );
}

function tabItem(tab: TabItem, isSelected: boolean, selectTab: (tab: TabItem) => void) {
  function handleClick() {
    if (!isSelected) {
      selectTab(tab);
    }
  }

  return (
    <button
      key={tab}
      className={`px-4 mx-4 py-1 ${isSelected ? `${DATA.pallette.activeBg}` : ""} rounded-lg ${DATA.pallette.hoverBg}`}
      onClick={handleClick}
    >
      {Tabs[tab].label}
    </button>
  );
}

export function Burger(props: TabsProps) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <button
        onClick={toggleOpen}
        className={`md:hidden fixed top-5 left-5 z-10 rounded-full ${DATA.pallette.activeBg} p-3 flex justify-center flex-row ${DATA.pallette.hoverBg}`}
      >
        <div>
          <div className={`h-1 w-7 mb-1 before:content-[' '] ${DATA.pallette.darkBg} rounded-full`}></div>
          <div className={`h-1 w-7 mb-1 before:content-[' '] ${DATA.pallette.darkBg} rounded-full`}></div>
          <div className={`h-1 w-7 before:content-[' '] ${DATA.pallette.darkBg} rounded-full`}></div>
        </div>
      </button>
      <div
        className={`flex flex-col py-20 ${
          DATA.pallette.overlayBg
        } h-screen fixed w-80 -left-80 opacity-0 z-20 transition ${!open ? "" : "translate-x-80 opacity-95"}`}
      >
        <button className={`absolute right-2 top-5 h-5 w-5 ${DATA.pallette.hoverBg} m-2`} onClick={toggleOpen}>
          <div className="absolute inset-y-1/2 w-5 bg-black h-0.5 rotate-45"></div>
          <div className="absolute inset-y-1/2 w-5 bg-black h-0.5 -rotate-45"></div>
        </button>
        {TabItems.map((x) => navItem(x, x === props.currentTab, props.selectTab, toggleOpen))}
      </div>
    </>
  );
}

function navItem(tab: TabItem, isSelected: boolean, selectTab: (tab: TabItem) => void, closeNav: () => void) {
  function handleClick() {
    if (!isSelected) {
      selectTab(tab);
    }
    closeNav();
  }

  return (
    <button key={"nav" + tab} className={`text-left px-5 py-2 font-serif text-xl`} onClick={handleClick}>
      {Tabs[tab].label}
    </button>
  );
}
