import React from "react";
import { TabContent, TabSection } from "./TabContent";
import { TabItem } from "./TabItems";
import { DATA } from "../../data/data";

export function Travel() {
  return (
    <TabContent tab={TabItem.Travel} mainImage={DATA.images[TabItem.Travel]}>
      {DATA.location && (
        <TabSection heading="Our Venue">
          <iframe
            className="w-full md:px-20"
            title="venue location"
            src={DATA.location.maps}
            height="450"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <h3 className="text-lg py-2">{DATA.location.name}</h3>
        </TabSection>
      )}
      <TabSection heading="Places to stay">
        <div className="px-5 md:px-20">
          <p className="font-serif-p font-light text-left py-5">
            If you haven't already done so, and if you require accommodation for the wedding, we have provided some
            useful links to book rooms close by. As we are tying the knot during a half term, availability may be
            limited, so we encourage you to make your arrangements as soon as possible. Fortunately there is a large
            number of hotels in nearby Crawley which is only 15 minutes from the venue in a taxi.
          </p>
          <ul className="list-item list-disc font-normal font-serif-p mx-5 text-left">
            <li>
              <a
                className="hover:underline"
                href="https://www.britanniahotels.com/hotels/the-europa-gatwick-hotel"
                target="_blank"
                rel="noreferrer"
              >
                Europa Hotel Gatwick
              </a>
            </li>
            <li>
              <a className="hover:underline" href="https://www.thecowdrayarms.co.uk" target="_blank" rel="noreferrer">
                Cowdray Arms
              </a>
            </li>
            <li>
              <a
                className="hover:underline"
                href="https://www.ihg.com/holidayinn/hotels/gb/en/crawley/lgwwo/hoteldetail"
                target="_blank"
                rel="noreferrer"
              >
                Holiday Inn London Gatwick
              </a>
            </li>
            <li>
              <a
                className="hover:underline"
                href="https://www.ihg.com/crowneplaza/hotels/gb/en/west-sussex/bsheg/hoteldetail"
                target="_blank"
                rel="noreferrer"
              >
                Crowne Plaza Felbridge
              </a>
            </li>
            <li>
              <a
                className="hover:underline"
                href="https://www.premierinn.com/gb/en/hotels/england/west-sussex/crawley/gatwick-crawley-town-west.html"
                target="_blank"
                rel="noreferrer"
              >
                Premier Inn Crawley West
              </a>
            </li>
          </ul>
        </div>
      </TabSection>
    </TabContent>
  );
}
