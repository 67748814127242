import React from "react";
import { TabContent, TabSection } from "./TabContent";
import { TabItem } from "./TabItems";
import { DATA } from "../../data/data";

export function Home() {
  const date = new Date();
  const wedding = DATA.date;
  const diff = wedding.getTime() - date.getTime();
  const daysDiff = diff / (1000 * 3600 * 24);
  const years = Math.floor(daysDiff / 365);
  const remainingDays = daysDiff % 365;
  const months = Math.floor(remainingDays / 30);
  const days = Math.floor(remainingDays % 30);

  return (
    <TabContent tab={TabItem.Home} mainImage={DATA.images[TabItem.Home]}>
      <TabSection heading="Countdown to the big day">
        <div className="flex flex-row justify-center space-x-10 py-5">
          {years > 0 ? (
            <div>
              <p>{years}</p>
              <p>{pluralize("Year", years > 1)}</p>
            </div>
          ) : null}
          {months > 0 ? (
            <div>
              <p>{months}</p>
              <p>{pluralize("Month", months > 1)}</p>
            </div>
          ) : null}
          {days > 0 ? (
            <div>
              <p>{days}</p>
              <p>{pluralize("Day", days > 1)}</p>
            </div>
          ) : null}
        </div>
      </TabSection>
    </TabContent>
  );
}

function pluralize(label: string, plural: boolean): string {
  if (plural) {
    return label + "s";
  }
  return label;
}
