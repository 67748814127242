import React from "react";
import { FieldErrors, FieldValues, Path, RegisterOptions, UseFormRegister } from "react-hook-form";

export type Input = {
  type: string;
};

export type Select = {
  options: string[] | { label: string; value: string }[];
};

export type Multiline = {
  rows: number;
};

export type File = {
  multiple: boolean;
  maxSize: number;
  accept: "image/*";
};

export type Element = Input | Select | Multiline | File;

const isMultiline = (e: Element): e is Multiline => (e as Multiline).rows !== undefined;
const isSelect = (e: Element): e is Select => (e as Select).options !== undefined;
const isInput = (e: Element): e is Input => (e as Input).type !== undefined;
const isFile = (e: Element): e is File => (e as File).maxSize !== undefined;

export interface FormGroupProps<TForm extends FieldValues> {
  register: UseFormRegister<TForm>;
  name: Path<TForm>;
  label?: string;
  errors: FieldErrors<TForm>;
  options?: RegisterOptions<TForm>;
  element: Element;
}

function getClassname(hasValidation: boolean, type?: string) {
  const commonClasses = "gap-2 md:col-span-4 focus:ring-0";

  if (type === "checkbox") {
    return `${commonClasses} border-emerald-600`;
  }

  return `${commonClasses} w-full border-0 border-b ${
    type !== "multiline" ? "focus:outline-none" : ""
  } focus:border-b-2 focus:border-emerald-600 border-slate-700 ${
    hasValidation ? "focus:border-red-600" : "focus:border-emerald-600"
  }`;
}

export function FormGroup<TForm extends FieldValues>(props: FormGroupProps<TForm>) {
  const validationMessage = errorWithFallback(props.label ?? props.name, props.errors, props.name);

  const commonProps = {
    placeholder: props.label,
    ...props.register(props.name, props.options),
  };

  return (
    <div className="grid md:grid-cols-5 grid-cols-1 justify-items-start items-center">
      {props.label && (
        <label className="justify-self-start text-left self-start mt-2">
          {props.label}
          {props.options?.required && "*"}
        </label>
      )}
      {isInput(props.element) && (
        <input
          type={props.element.type}
          className={getClassname(validationMessage, props.element.type)}
          {...commonProps}
        ></input>
      )}
      {isSelect(props.element) && (
        <select className={getClassname(validationMessage)} {...commonProps} defaultValue={""}>
          <option disabled value="">
            Please select an option
          </option>
          {props.element.options.map((value, i) => (
            <option key={i} value={typeof value == "string" ? value : value.value}>
              {typeof value == "string" ? value : value.label}
            </option>
          ))}
        </select>
      )}
      {isMultiline(props.element) && (
        <textarea
          className={getClassname(validationMessage, "multiline")}
          {...commonProps}
          rows={props.element.rows}
        ></textarea>
      )}
      {isFile(props.element) && (
        <input
          className="gap-2 md:col-span-5 w-full font-serif-p text-sm text-black border border-green-300 rounded-lg cursor-pointer focus:outline-none"
          type="file"
          multiple={props.element.multiple}
          {...commonProps}
          placeholder="No file chosen"
        ></input>
      )}
      {validationMessage && (
        <p className="md:col-start-2 md:col-span-4 text-red-600 font-semibold text-xs">{validationMessage}</p>
      )}
    </div>
  );
}

function errorWithFallback<TForm extends FieldValues>(label: string, errors: FieldErrors<TForm>, name: string) {
  let error: any = errors;
  for (var key of name.split(".")) {
    error = error[key];
    if (!error) return undefined;
  }

  if (error?.message) return error.message.toString();

  switch (error.type) {
    case "pattern":
      return `"${label}" is invalid.`;
    case "required":
      return `"${label}" is required.`;
    default:
      return `"${label}" has an error`;
  }
}
