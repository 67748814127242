import React, { useState } from "react";
import { TabContent, TabSection } from "./TabContent";
import { TabItem } from "./TabItems";
import { useForm } from "react-hook-form";
import { FormGroup } from "../forms/Forms";
import { Spinner } from "../forms/Spinner";
import { DATA } from "../../data/data";

const development = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
const apiAddress = (path: string) =>
  development ? `http://localhost:9000/prod/${path}` : `https://api.${DATA.domain}/${path}`;

export function Rsvp() {
  const [inviteInfo, setInviteInfo] = useState<InviteInfo | undefined>(undefined);
  const [guests, setGuests] = useState<Record<string, GuestInfo> | undefined>(undefined);

  return (
    <TabContent tab={TabItem.Rsvp} mainImage={DATA.images[TabItem.Rsvp]}>
      <TabSection heading="RSVP">
        <p className="font-serif-p font-light pt-5 px-20">
          {!guests && "Thank you for taking the time to reply to our wedding invitation."}
        </p>
        {!inviteInfo && !guests && <Step1Form callback={setInviteInfo} />}
        {inviteInfo && !guests && (
          <Step2Form
            invite={inviteInfo}
            callback={(guests) => {
              setGuests(guests);
            }}
          />
        )}
        {guests && inviteInfo && isComing(guests) && <Thanks invite={inviteInfo} />}
        {guests && inviteInfo && !isComing(guests) && <Apologies />}
      </TabSection>
    </TabContent>
  );
}

function isComing(guests: Record<string, GuestInfo>) {
  for (const guest of Object.values(guests)) {
    if (guest.status !== notComing) {
      return true;
    }
  }

  return false;
}

function Thanks(props: { invite: InviteInfo }) {
  return (
    <div className="text-left font-serif-p font-light p-5 md:px-16 md:mx-10">
      <p>
        Woo! You’re in! We are thrilled to have received your RSVP. Your presence on our special day means the world to
        us, and we can't wait to celebrate with you.
      </p>
      <h3 className="p-5 text-center font-semibold underline">What's next</h3>
      <Accomodation invite={props.invite} />
      <p className="pt-5">
        Additionally, to stay up-to-date with any last-minute changes, important details, or exciting updates regarding
        the wedding, please keep an eye on your email inbox. We will be using email as our primary means of
        communication to ensure that everything goes smoothly on the big day.
      </p>
      <p className="pt-5">Once again, thank you for your RSVP. Let the countdown begin!</p>
    </div>
  );
}

function Accomodation(props: { invite: InviteInfo }) {
  if (props.invite.onVenue) {
    return (
      <p className="font-serif-p font-light">
        We would love for you to join us and stay at the venue. Don't worry, your room is already reserved, and we will
        be in contact with booking information soon.
      </p>
    );
  }

  return (
    <>
      <p className="font-serif-p font-light">
        If you haven't already done so, and if you require accommodation for the wedding, we have provided some useful
        links to book rooms close by. As we are tying the knot during a half term, availability may be limited, so we
        encourage you to make your arrangements as soon as possible. Fortunately there is a large number of hotels in
        nearby Crawley which is only 15 minutes from the venue in a taxi.
      </p>
      <ul className="list-item list-disc mx-10 pt-5 decoration-green-600 font-normal">
        <li>
          <a
            className="hover:underline"
            href="https://www.britanniahotels.com/hotels/the-europa-gatwick-hotel"
            target="_blank"
            rel="noreferrer"
          >
            Europa Hotel Gatwick
          </a>
        </li>
        <li>
          <a className="hover:underline" href="https://www.thecowdrayarms.co.uk" target="_blank" rel="noreferrer">
            Cowdray Arms
          </a>
        </li>
        <li>
          <a
            className="hover:underline"
            href="https://www.ihg.com/holidayinn/hotels/gb/en/crawley/lgwwo/hoteldetail"
            target="_blank"
            rel="noreferrer"
          >
            Holiday Inn London Gatwick
          </a>
        </li>
        <li>
          <a
            className="hover:underline"
            href="https://www.ihg.com/crowneplaza/hotels/gb/en/west-sussex/bsheg/hoteldetail"
            target="_blank"
            rel="noreferrer"
          >
            Crowne Plaza Felbridge
          </a>
        </li>
        <li>
          <a
            className="hover:underline"
            href="https://www.premierinn.com/gb/en/hotels/england/west-sussex/crawley/gatwick-crawley-town-west.html"
            target="_blank"
            rel="noreferrer"
          >
            Premier Inn Crawley West
          </a>
        </li>
      </ul>
    </>
  );
}

function Apologies() {
  return (
    <p className="font-serif-p font-light">
      So sorry you’re not able to attend! We wanted to express how much we'll miss you at our upcoming wedding. Although
      you won't be able to join us in person, we can't wait to catch up and share all the wonderful memories when we see
      you next.
    </p>
  );
}

type Step1FormData = {
  code: string;
};

interface InviteInfo {
  code: string;
  type: "evening" | "ceremony";
  guests: string[];
  onVenue: boolean;
}

function Step1Form(props: { callback: (i: InviteInfo) => void }) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Step1FormData>();

  const [error, setError] = useState<string | undefined>(undefined);

  const submitStartRSVP = async (data: Step1FormData) => {
    setLoading(true);
    try {
      const res = await fetch(apiAddress(`invite/${data.code}`), {
        headers: { "x-api-key": "atqWTW6fFt5D5Hs2E7Tmka6IovbzATlf9We0wYYm" },
      });
      if (!res.ok) {
        throw res.status;
      }

      const invite = (await res.json()) as InviteInfo;
      invite.code = data.code;
      props.callback(invite);
    } catch (e) {
      switch (e) {
        case 400:
          setError(
            "Your code appears to be invalid. Please check and try again. If you have already submitted your RSVP and wish to update your information, please contact us directly."
          );
          break;
        default:
          setError("An unexpected error occured - please let us know if this persists.");
      }
      setLoading(false);
    }
  };

  return (
    <>
      <p className="font-serif-p font-light">Please provide RSPV code to begin.</p>

      {!loading ? (
        <form
          className="font-serif-p grid grid-cols-1 gap-3 max-w-2xl mx-auto p-5"
          onSubmit={handleSubmit(submitStartRSVP)}
          noValidate={true}
        >
          <FormGroup<Step1FormData>
            element={{ type: "text" }}
            label="RSVP Code"
            name="code"
            register={register}
            errors={errors}
            options={{
              required: true,
            }}
          />
          <button
            type="submit"
            className="rounded-full font-serif bg-green-300 justify-self-center px-5 mt-5 font-bold"
          >
            Reply
          </button>
          {error && <p className="text-sm text-red-500 font-semibold">{error}</p>}
        </form>
      ) : (
        <Spinner className="mt-5"></Spinner>
      )}
    </>
  );
}

type Step2FormData = { guests: Record<string, GuestInfo>; invite: string; song: string };
type GuestInfo = {
  guest: string;
  invite: string;
  status: string;
  email: string;
  dietary: string;
  plusOne: boolean;
};

const notComing = "notComing";

function Step2Form(props: { invite: InviteInfo; callback: (guests: Record<string, GuestInfo>) => void }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Step2FormData>();

  const submitRsvp = async (data: Step2FormData) => {
    setLoading(true);
    data.invite = props.invite.code;
    try {
      const res = await fetch(apiAddress(`reply`), {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "atqWTW6fFt5D5Hs2E7Tmka6IovbzATlf9We0wYYm",
        },
      });
      if (!res.ok) {
        throw res;
      }
      props.callback(data.guests);
    } catch (e: any) {
      if (e.status === 400) {
        setError(await e.text());
      } else {
        setError("An unexpected error occured - please let us know if this persists.");
      }
      setLoading(false);
    }
  };

  const options = [
    { label: "I'll be there!", value: props.invite.type },
    { label: "Unable to make it", value: notComing },
  ];
  if (props.invite.type === "ceremony") {
    options.push({ label: "I'll join you in the evening", value: "evening" });
  }

  return (
    <>
      <p className="font-serif-p font-light max-w-3xl mx-auto mt-5">
        {props.invite.type === "ceremony"
          ? `Dear ${guestList(
              props.invite.guests
            )}, we would be delighted if you could join us to celebrate our wedding. Please provide the following information.`
          : `Dear ${guestList(
              props.invite.guests
            )}, we would be delighted if you could join us for an evening celebration of our marriage. Please provide the following information.`}
      </p>
      {!loading ? (
        <form
          className="font-serif-p grid grid-cols-1 gap-3 max-w-2xl mx-auto p-5"
          onSubmit={handleSubmit(submitRsvp)}
          noValidate={true}
        >
          {props.invite.guests.map((guest, i) => {
            const getName = (field: string): `guests.${string}` => `guests.${guest}.${field}`;

            return (
              <div key={i}>
                <h3 className="text-left mt-3 font-semibold">{guest}</h3>
                <input type="hidden" value={guest} {...register(getName("guest"))} />
                <input type="hidden" value={props.invite.code} {...register(getName("invite"))} />
                <FormGroup
                  element={{
                    options: options,
                  }}
                  label="Can attend?"
                  name={getName("status")}
                  register={register}
                  errors={errors}
                  options={{
                    required: true,
                  }}
                />
                <FormGroup
                  element={{ type: "text" }}
                  label="Email"
                  name={getName("email")}
                  register={register}
                  errors={errors}
                  options={{
                    required: i === 0,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Must be a valid email address.",
                    },
                  }}
                />
                <FormGroup
                  element={{
                    rows: 1,
                  }}
                  label="Dietary requirements"
                  name={getName("dietary")}
                  register={register}
                  errors={errors}
                />
                {props.invite.type === "evening" && props.invite.guests.length === 1 && (
                  <FormGroup
                    element={{ type: "checkbox" }}
                    label="Bringing a plus one?"
                    name={getName("plusOne")}
                    register={register}
                    errors={errors}
                  />
                )}
                <hr className="mt-7 h-0.5 bg-green-300 rounded-full mx-auto" />
              </div>
            );
          })}
          <FormGroup
            element={{ type: "text" }}
            label="A song for the dance floor"
            name="song"
            register={register}
            errors={errors}
            options={{ required: true }}
          />
          <button
            type="submit"
            className="rounded-full font-serif bg-green-300 justify-self-center px-5 mt-5 font-bold"
          >
            Send RSVP
          </button>
          {error && <p className="text-sm text-red-500 font-semibold">{error}</p>}
        </form>
      ) : (
        <Spinner className="mt-5"></Spinner>
      )}
    </>
  );
}

function guestList(guests: string[]) {
  const firstNames = guests.map((x) => x.split(" ")[0]);
  if (firstNames.length === 1) {
    return firstNames;
  }

  return firstNames.slice(0, -1).join(", ") + " and " + firstNames.slice(-1);
}
