import React from "react";
import { TabContent, TabSection } from "./TabContent";
import { TabItem } from "./TabItems";
import { DATA } from "../../data/data";

export function Faq() {
  return (
    <TabContent tab={TabItem.Faq} mainImage={DATA.images[TabItem.Faq]}>
      <TabSection heading="Frequently Asked Questions">
        {DATA.faqs.map((x) => (
          <Question question={x.question}>{x.answer}</Question>
        ))}
      </TabSection>
    </TabContent>
  );
}

interface QuestionProps {
  question: string;
  children: string;
}

function Question(props: QuestionProps) {
  return (
    <div className="text-left pt-5 md:px-20">
      <h3 className="font-semibold pb-2">{props.question}</h3>
      <p className="px-5 md:px-10 font-serif-p font-light">{props.children}</p>
    </div>
  );
}
