import React from "react";

export function Footer() {
  return (
    <footer className="px-auto py-5 text-center">
      <p className="text-xs">
        Website designed and created by{" "}
        <a className="underline" href="mailto:bentysonscott@gmail.com">
          Ben Scott
        </a>
      </p>
    </footer>
  );
}
