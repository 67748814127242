import React from "react";
import { TabContent, TabSection } from "./TabContent";
import { TabItem } from "./TabItems";
import { DATA } from "../../data/data";

export function Gifts() {
  return (
    <TabContent tab={TabItem.Gifts} mainImage={DATA.images[TabItem.Gifts]}>
      <TabSection heading="Gifts">{DATA.gifts}</TabSection>
    </TabContent>
  );
}
