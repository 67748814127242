import React, { useEffect, useState } from "react";
import { Header } from "./components/header/Header";
import { DesktopTabBar, Burger, Tabs } from "./components/tabs/Tabs";
import { Footer } from "./components/footer/Footer";
import { RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import ErrorPage from "./pages/Error";
import { DATA } from "./data/data";
import { TabItem } from "./components/tabs/TabItems";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: Object.values(Tabs).filter((x) => x.enabled),
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  const defaultTab = () => {
    const value = Object.entries(Tabs).reduce(
      (prev, [k, v]) => ({
        [v.path]: parseInt(k),
        ...prev,
      }),
      {} as Record<string, number>
    )[window.location.pathname.substring(1)];
    return value;
  };
  const [tab, setTab] = useState(defaultTab);

  const navigate = useNavigate();
  var selectTab = (t: TabItem) => {
    setTab(t);
    navigate(Tabs[t].path);
  };

  useEffect(() => {
    async function preload(src: string) {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = function () {
          resolve();
        };
        img.onerror = function () {
          reject();
        };
      });
    }

    const promises = Object.values(DATA.images).map((x) => preload(x.src));

    Promise.all(promises);
  }, []);

  return (
    <>
      <Burger selectTab={selectTab} currentTab={tab}></Burger>
      <div style={{ backgroundImage: `url(${DATA.background})` }} className="flex flex-col bg-contain bg-fixed">
        <div className="max-w-5xl container mx-auto bg-white h-fit min-h-screen drop-shadow-xl">
          <Header></Header>
          <DesktopTabBar selectTab={selectTab} currentTab={tab}></DesktopTabBar>
          <Outlet />
          <Footer></Footer>
        </div>
      </div>
    </>
  );
}

export default App;
