import React from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError() as any;

  return (
    <div className="flex flex-col justify-center font-serif-p text-center h-screen flex-wrap">
      <h1 className="text-7xl font-bold my-7">Oops!</h1>
      <p className="my-2">Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
