import { TenantData } from "./data_base";
import React from "react";
import img1 from "../components/tabs/images/img1.jpg";
import img2 from "../components/tabs/images/img2.jpg";
import img3 from "../components/tabs/images/img3.jpg";
import img4 from "../components/tabs/images/img4.jpg";
import { TabItem } from "../components/tabs/TabItems";
import background from "../shutterstock_695562169.jpg";

export const DATA: TenantData = {
  date: new Date(2024, 4, 31),
  background: background,
  pallette: {
    activeBg: "bg-green-300",
    hoverBg: "hover:bg-green-400",
    overlayBg: "bg-green-50",
    darkBg: "bg-green-700",
  },
  names: "Ben & Katherine",
  domain: "benandkatherine.co.uk",
  images: {
    [TabItem.Home]: { src: img1, height: 600 },
    [TabItem.Faq]: { src: img2, height: 600 },
    [TabItem.Travel]: { src: img3, height: 500 },
    [TabItem.Rsvp]: { src: img3, height: 500 },
    [TabItem.Gifts]: { src: img4, height: 600 },
  },
  location: {
    maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507.0246266622694!2d-0.14706508407786348!3d51.07109545033014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875f24964162847%3A0x6e4a34d3926373a8!2sHighley%20Manor%20Wedding%20Venue!5e0!3m2!1sen!2suk!4v1675069515227!5m2!1sen!2suk",
    name: "Highely Manor",
  },
  faqs: [
    {
      question: "How do I RSVP for the wedding?",
      answer: ` 
          A: To RSVP for the wedding, please click on the RSVP link provided on the wedding website homepage. We decided
          not to include a physical RSVP with our invites. You will be directed to a form on your invite to provide all
          the necessary details.
      `,
    },
    {
      question: "Can I bring a plus one?",
      answer: ` 
          A: Unfortunately, due to space limitations, we are unable to accomodate any additional guests to the ceremony.
      `,
    },
    {
      question: "What is the dress code for the wedding?",
      answer: ` 
          A: The dress code for the wedding is formal attire. Wear something you love and that you can dance in!
      `,
    },
    {
      question: "Is there a gift registry for the wedding?",
      answer: ` 
          A: We appreciate any gifts but your presence on our special day is all that we could ask for. If you would
          like to give us a gift, we have created a gifts sections available on the wedding website where you can see
          suggestions.
      `,
    },
    {
      question: "Can I take pictures at the wedding?",
      answer: ` 
          A: Of course! We encourage you to capture as many memories as possible. However, we kindly ask that you
          refrain from taking any pictures during the ceremony.
      `,
    },
    {
      question: "What time does the wedding start?",
      answer: ` 
          A: The ceremony will begin promptly at 1pm, and you're welcome to arrive at Highley Manor from 12pm. Please
          arrive by 12:30pm latest.
      `,
    },
    {
      question: "Is there a reception following the ceremony?",
      answer: ` 
          A: Yes, there will be a wedding breakfast immediately following the ceremony and we are expecting evening
          guests from 6.30pm. Food and drinks will be provided in the evening too, followed by a paid bar.
      `,
    },
    {
      question: "Can I stay on-site?",
      answer: ` 
          A: There are only 18 rooms at Highley, and we will be prioritising family, groomsmen and bridal party. Please
          see the 'travel' tab for plenty of alternatives & travel details.
      `,
    },
    {
      question: "What time does the wedding end?",
      answer: ` 
          A: Carriages are at 12.30am, and Highley politely requests that all guests are off site by 1am.
      `,
    },
    {
      question: "I can't make it to the daytime events... can I still come to the evening party?",
      answer: ` 
          A: YES! Absolutely. Just let us know when you RSVP. We will be in the area for Saturday as well, so if you are
          staying nearby, there will be plenty of time to catch up.
      `,
    },
  ],
  gifts: (
    <p className="font-serif-p font-light pt-5 px-5 md:px-16 text-left">
      We are incredibly grateful for your presence on our special day, and your love and support mean the world to us.
      Therefore, while we do not have a traditional gift registry, your thoughtfulness is truly appreciated. If you
      would like to contribute in a meaningful way, we have set up a link where you can help us make our dream honeymoon
      a reality.
      <br />
      <br />
      Please follow this link to{" "}
      <a className="underline text-green-700" href="https://www.trailfinders.com/wishlist#/">
        Our Wishlist
      </a>{" "}
      and under "Make A Contribution to a Wishlist" use our code 5BM7X1 and our (soon to be shared) surname Scott.
      <br />
      <br />
      Any contributions will create memories that we will cherish forever. Equally, we will have a post box for cards
      set up on the day. However, please know that your presence at our wedding is the greatest gift of all, and we are
      so excited to party with you all!
    </p>
  ),
  photos: true,
};
