import React, { useState } from "react";
import { TabContent, TabSection } from "./TabContent";
import { TabItem } from "./TabItems";
import { useForm } from "react-hook-form";
import { Spinner } from "../forms/Spinner";
import { FormGroup } from "../forms/Forms";
import { DATA } from "../../data/data";

interface PhotoForm {
  files: FileList;
}

const development = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
const apiAddress = (path: string) =>
  development ? `http://localhost:9000/prod/${path}` : `https://api.${DATA.domain}/${path}`;

export function Photos() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<PhotoForm>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [counter, setCounter] = useState(0);
  const [total, setTotal] = useState(0);

  const uploadSubmit = async (data: PhotoForm) => {
    setLoading(true);
    let size = 0;
    const files = [];
    for (const file of data.files) {
      size += file.size;
      files.push({ filename: file.name, len: file.size });
    }
    console.log(files);
    if (size > 5_000_000_000) {
      setError("Upload is too large.");
      setLoading(false);
      return;
    }

    try {
      setTotal(files.length);
      setCounter(0);
      const res = await fetch(apiAddress(`upload`), {
        method: "POST",
        body: JSON.stringify(files),
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "atqWTW6fFt5D5Hs2E7Tmka6IovbzATlf9We0wYYm",
        },
      });
      if (!res.ok) {
        throw res.status;
      }
      const urls = (await res.json()) as { url: string; headers: [string, string][] }[];
      const uploads = [];
      setTotal(urls.length);
      for (let i = 0; i < urls.length; i++) {
        const init: RequestInit = {
          method: "PUT",
          body: data.files[i],
          headers: urls[i].headers,
        };
        uploads.push(fetch(urls[i].url, init).finally(() => setCounter((prev) => prev + 1)));
      }
      await Promise.all(uploads);
      reset();
      setError(undefined);
    } catch {
      setError("An unexpected error occured - please let us know if this persists.");
    }

    setLoading(false);
  };

  return (
    <TabContent tab={TabItem.Photos}>
      <TabSection heading="Photos">
        <p className="font-serif-p my-5">
          We would love to see you photos. Please upload any you'd like to share here:
        </p>
        {!loading ? (
          <form
            className="font-serif-p grid grid-cols-1 gap-3 max-w-2xl mx-auto p-5"
            onSubmit={handleSubmit(uploadSubmit)}
            noValidate={true}
          >
            <FormGroup<PhotoForm>
              element={{ multiple: true, maxSize: 100, accept: "image/*" }}
              name="files"
              register={register}
              errors={errors}
              options={{
                required: true,
              }}
            />
            <button
              type="submit"
              className="rounded-full font-serif bg-green-300 justify-self-center px-5 mt-5 font-bold"
            >
              Send
            </button>
            {error && <p className="text-sm text-red-500 font-semibold">{error}</p>}
          </form>
        ) : (
          <>
            <Spinner className="mt-5"></Spinner>
            <p>
              Uploaded {counter} of {total}
            </p>
          </>
        )}
      </TabSection>
    </TabContent>
  );
}
